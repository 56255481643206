import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button } from 'antd'
import { useGetEventUserQuery, useUpdateEventDayUserMutation, useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'
import Form from '../../shared/form'
import InputRadioCheckbox from '../../shared/form/input.radio.checkbox'
import useHistoryCustom from '../../hooks/useHistoryCustom'
import { useSteps } from './Steps'

const Participation = () => {
  const [selected, setSelected] = useState()
  const [inWaitingList, setInWaitingList] = useState(false)

  const { settings, event, myContext, userId, user: me } = useMyContext()
  const { setUserId, nextStep, setParticipation, goToEnd, participation } = useSteps()
  const { showRepresentedBy } = useConf(participation)
  const history = useHistoryCustom()
  const { showParticipation } = useConf(participation)

  const { data: eventUser } = useGetEventUserQuery({ ...myContext })
  const [updateEventUser, { isLoading }] = useUpdateEventUserMutation()
  const [updateEventDay] = useUpdateEventDayUserMutation()

  const options = useMemo(() => {
    const o = []

    if (
      !settings?.FO_MEETING_PARTICIPATON_HIDE_IS_NOT_REMOTE &&
      (event.canRegister || (eventUser?.UserEvent?.isRegistered && !eventUser?.UserEvent?.isRemote))
    ) {
      o.push({
        label: settings?.FO_MEETING_PARTICIPATON_TEXT_IS_NOT_REMOTE || 'Je participe en présentiel',
        value: '4',
      })
    }

    if (!settings?.FO_MEETING_PARTICIPATON_HIDE_IS_REMOTE) {
      o.push({
        label: settings?.FO_MEETING_PARTICIPATON_TEXT_IS_REMOTE || 'Je participe en distanciel',
        value: '1',
      })
    }

    if (showRepresentedBy) {
      o.push({
        label: settings?.FO_MEETING_PARTICIPATON_REPRESENTED_BY_TEXT || 'Je ne participe pas mais je serai représenté',
        value: '2',
      })
    }

    if (!settings?.FO_MEETING_PARTICIPATON_HIDE_NO) {
      o.push({
        label: settings?.FO_MEETING_PARTICIPATON_TEXT_NO || 'Je ne participe pas',
        value: '3',
      })
    }

    return o
  }, [event, eventUser, settings, showRepresentedBy])

  useEffect(() => {
    if (showParticipation) {
      const e = eventUser?.UserEvent
      if (e?.registered && e?.isRemote) {
        setSelected(1)
        setUserId(userId)
      } else if (e?.registered) {
        setSelected(4)
        setUserId(userId)
      } else if (e?.representedBy) {
        setSelected(2)
        setUserId(null)
      } else if (e?.responded) {
        setSelected(3)
        setUserId(userId)
      }
    } else {
      setParticipation(4)
      setUserId(userId)
      nextStep()
    }
  }, [eventUser, nextStep, setParticipation, setUserId, showParticipation, userId])

  const setWaitingList = bool => async () => {
    // const promises = [
    //   ...event.days.map(ed => {
    //     return updateEventDay({
    //       ...myContext,
    //       userId,
    //       dayId: ed.id,
    //       day: { registered: false, registeredAt: null },
    //     }).unwrap()
    //   }),
    // ]

    await Promise.all([
      updateEventUser({
        ...myContext,
        eventUser: {
          responded: true,
          // isRemote: null,
          extended: {
            ...(eventUser?.UserEvent?.extended || {}),
            inWaitingList: bool ? { oui: true } : { non: true },
          },
        },
      }).unwrap(),
      // ...promises,
    ])

    setInWaitingList(true)
    setTimeout(() => history.push(''), 5000)
  }

  if (inWaitingList) {
    return (
      <div style={{ padding: 16 }}>
        <Alert type="success" message="Votre réponse a bien été prise en compte. Vous allez être redirigé dans 5 secondes." showIcon />
      </div>
    )
  }

  return (
    <Form
      disabled={!selected}
      onSubmit={async () => {
        setParticipation(Number(selected))

        if (Number(selected) === 3) {
          const promises = [
            ...event.days.map(ed => {
              return updateEventDay({
                ...myContext,
                userId,
                dayId: ed.id,
                day: { registered: false, registeredAt: null },
              }).unwrap()
            }),
          ]
          await Promise.all([
            ...promises,
            updateEventUser({
              ...myContext,
              eventUser: {
                registeredFrom: null,
                registeredBy: null,
                representedBy: null,
                responded: true,
                isRemote: null,
                isExhibitor: false,
                extended: {
                  ...(eventUser?.UserEvent?.extended || {}),
                  PARTICIPATION_IS_REMOTE: null,
                },
                typo: me.isExhibitor && settings?.FO_MEETING_TYPO_DEFAULT_EXHIBITOR ? settings.FO_MEETING_TYPO_DEFAULT_EXHIBITOR : me.typo,
                typoId:
                  me.isExhibitor && settings?.FO_MEETING_TYPO_V2_DEFAULT_EXHIBITOR
                    ? settings.FO_MEETING_TYPO_V2_DEFAULT_EXHIBITOR
                    : me.typoId,
              },
            }).unwrap(),
          ])
          return setTimeout(() => goToEnd(), 200)
        }

        await updateEventUser({
          ...myContext,
          eventUser: {
            isExhibitor: false,
            typo: me.isExhibitor && settings?.FO_MEETING_TYPO_DEFAULT_EXHIBITOR ? settings.FO_MEETING_TYPO_DEFAULT_EXHIBITOR : me.typo,
            typoId:
              me.isExhibitor && settings?.FO_MEETING_TYPO_V2_DEFAULT_EXHIBITOR ? settings.FO_MEETING_TYPO_V2_DEFAULT_EXHIBITOR : me.typoId,
          },
        }).unwrap()

        return setTimeout(() => nextStep(), 200)
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {settings?.FO_MEETING_PARTICIPATION_HTML && (
          <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_PARTICIPATION_HTML.html }} />
        )}
        {!options?.find(f => f.value === '4') && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center', marginBottom: 16 }}>
            {settings?.FO_MEETING_PARTICIPATION_QUOTA_HTML && (
              <div style={{ padding: 16 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_PARTICIPATION_QUOTA_HTML.html }} />
            )}

            {!inWaitingList && (
              <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 8 }}>
                <Button type="primary" onClick={setWaitingList(true)} loading={isLoading}>
                  {settings?.FO_MEETING_PARTICIPATION_QUOTA_YES || "Oui, je veux être averti d'une place de libre"}
                </Button>
                <Button onClick={setWaitingList(false)} loading={isLoading}>
                  {settings?.FO_MEETING_PARTICIPATION_QUOTA_NO || "Non, ça m'intéresse pas"}
                </Button>
              </div>
            )}
          </div>
        )}
        <InputRadioCheckbox
          label={!settings?.FO_MEETING_PARTICIPATION_HTML ? 'Voulez-vous participer à cet événement ? ' : ''}
          options={options}
          selectedValues={[`${selected}`]}
          type="radio"
          setSelectedValue={v => {
            setSelected(v)

            if (['1', '3', '4'].includes(v)) {
              setUserId(userId)
            } else {
              setUserId(null)
            }
          }}
          required
        />
      </div>
    </Form>
  )
}

export default Participation
