import React, { FormEvent, useEffect, useRef, useState } from 'react'
import nextId from 'react-id-generator'
import { InputNumber } from 'antd'
import { useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useCreateUserMutation, useUpdateUserMutation } from '../../api/user.api'
import { useMyContext } from '../../context/context'
import useHistoryCustom from '../../hooks/useHistoryCustom'
import { IFormCreate, IModel } from '../../interfaces/form'
import { IUser } from '../../interfaces/user'
import MessageError from '../message.error'
import Button from './button'
import Input from './input'
import InputRadioCheckbox from './input.radio.checkbox'
import Select from './Select'
import TextArea from './textarea'

interface IProps {
  user?: IUser
  company?: any
  backLink?: string
  backLabel?: string
  form?: IFormCreate | null
  afterSave?: any
  afterSave2?: any
  beforeSubmit?: any
  NextButton?: any
  disabled?: boolean
  hideButton?: boolean
  setRef?: any
  onSaveCompany?: any
}

const Form = ({
  user,
  company,
  backLabel,
  backLink,
  form,
  afterSave,
  afterSave2,
  beforeSubmit,
  NextButton,
  disabled,
  hideButton,
  setRef,
  onSaveCompany,
}: IProps) => {
  const history = useHistoryCustom()

  const [activePage, setActivePage] = useState<number>(0)
  const [userState, setUser] = useState<any>(user || {})
  const [companyState, setCompany] = useState<any>(company || {})
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const ref = useRef(null)

  const { domainId, settings, myContext } = useMyContext() as any

  const [createUser] = useCreateUserMutation()
  const [updateUserEvent] = useUpdateEventUserMutation()
  const [updateUser] = useUpdateUserMutation()

  useEffect(() => {
    if (company) {
      setCompany(company)
    }
  }, [company])

  useEffect(() => {
    if (ref?.current && setRef) {
      setRef(ref.current)
    }
  }, [ref, setRef])

  useEffect(() => {
    if (user) {
      setUser(user)
    }
  }, [user, form])

  const getStateFromModel = (model: IModel, fromOld?: boolean) => {
    const uState = fromOld ? user : userState
    const cState = fromOld ? company : companyState
    if (model.entity === 'user') return uState
    if (model.entity === 'company') return cState
    if (model.entity === 'authentication') return uState?.Authentication || {}
    if (model.entity === 'userEvent') return uState?.UserEvent || {}
    return null
  }

  const getSetStateFromModel = (model: IModel) => {
    if (model.entity === 'user') return setUser
    if (model.entity === 'company') return setCompany
    if (model.entity === 'authentication')
      return (auth: any) =>
        setUser({
          ...userState,
          email: auth ? auth.email : undefined,
          password: auth ? auth.password : undefined,
          Authentication: auth,
        })
    if (model.entity === 'userEvent')
      return (UserEvent: any) =>
        setUser({
          ...userState,
          UserEvent,
        })
    return null
  }

  const getValue = (model: IModel, fromOld?: boolean) => {
    const state = getStateFromModel(model, fromOld)
    if (!state) return null

    if (model.field === 'extended') return state && state.extended && state.extended[model.fieldExtended]
    return state && state[model.field]
  }

  const setValue = (value: string, model: any) => {
    const state = getStateFromModel(model)
    const setState = getSetStateFromModel(model)
    if (!state || !setState) return

    let newValue = value.length === 1 ? value.trim() : value
    if (model.caps) {
      newValue = value.toUpperCase()
    } else if (model.camelcase) {
      newValue = value
    }

    if (model.field === 'extended')
      setState({
        ...state,
        extended: { ...state.extended, [model.fieldExtended]: newValue },
      })
    else setState({ ...state, [model.field]: newValue })
  }

  const getSelectValue = (model: IModel) => {
    const state = getStateFromModel(model)

    if (!state || !state.extended || !state.extended[model.fieldExtended]) return ''

    const entries = Object.entries(state.extended[model.fieldExtended])
    const find = entries.find(e => e[1])
    if (find) return find[0]
    return ''
  }

  const setSelectValue = (value: string, model: IModel) => {
    const state = getStateFromModel(model)
    const setState = getSetStateFromModel(model)
    if (!state || !setState) return

    setState({
      ...state,
      extended: {
        ...state.extended,
        [model.fieldExtended]: model.values.reduce((obj: any, current) => {
          if (value === current.field) return { [current.field]: true }
          return obj
        }, undefined),
      },
    })
  }

  const getSelectedValues = (model: IModel) => {
    const state = getStateFromModel(model)

    const values: string[] = []

    if (!state || !state.extended || !state.extended[model.fieldExtended]) return values
    const entries = Object.entries(state.extended[model.fieldExtended])
    entries.forEach(e => e[1] && values.push(e[0]))
    return values
  }

  const setSelectedValues = (value: string, model: IModel) => {
    const state = getStateFromModel(model)
    const setState = getSetStateFromModel(model)
    if (!state || !setState) return

    setState({
      ...state,
      extended: {
        ...state.extended,
        [model.fieldExtended]: model.values.reduce((obj: any, current) => {
          const actual = state.extended?.[model.fieldExtended]?.[current.field] || false

          if (current.field === value) {
            if (actual) return obj
            return { ...obj, [current.field]: true }
          }

          if (actual) return { ...obj, [current.field]: true }
          return obj
        }, {}),
      },
    })
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setError('')
    setLoading(true)
    event.preventDefault()

    try {
      if (onSaveCompany) {
        return onSaveCompany(companyState)
      }

      const userId = beforeSubmit ? (await beforeSubmit()) || userState?.id : userState?.id

      const u = await (userId
        ? updateUser({ domainId, userId, user: { ...(userState || {}), isExhibitor: settings?.FO_MEETING_IS_EXHIBITOR_DEFAULT } }).unwrap()
        : createUser({
            ...myContext,
            user: { ...(userState || {}), isExhibitor: settings?.FO_MEETING_IS_EXHIBITOR_DEFAULT },
            force: !!settings.FO_MEETING_FORM_FORCE_UPDATE,
          }).unwrap())
      if (userState.UserEvent) {
        const a = await updateUserEvent({ ...myContext, userId: u.id, eventUser: userState.UserEvent }).unwrap()
      }
      if (afterSave) {
        await afterSave(u.id)
      }
      if (afterSave2) {
        await afterSave2(u)
      }
      if (onSaveCompany) {
        await onSaveCompany(companyState)
      }
    } catch (err) {
      setError(err.message || err.data?.message)
    } finally {
      setLoading(false)
    }
  }

  if (!form) return <div>Vous devez choisir un formulaire</div>

  if (!form.pages.length) return <div>Le formulaire doit contenir 1 page au minimum</div>

  return (
    <div>
      <form onSubmit={onSubmit} ref={ref}>
        {form.pages.length > 1 && (
          <div className="tabs is-medium">
            <ul>
              {form.pages.map((p, i) => (
                <li key={nextId('tab-top-')} className={`${i === activePage && 'is-active'}`}>
                  <a onClick={() => setActivePage(i)}>{p.label}</a>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="form">
          {form.pages.map((p, i) => (
            <div key={`form-page-${i}`} id={`page-${i}`} className={`field ${i !== activePage && 'is-hidden'}`}>
              {p.models.map((m: any, mi) => {
                if (m.field !== 'extended' && userState.canUpdate === false) return null
                if (m.component === 'input')
                  return (
                    <Input
                      key={`form-model-${mi}`}
                      value={getValue(m)}
                      onChange={(value: any) => setValue(value, m)}
                      type={m.type || 'text'}
                      label={m.label}
                      required={m.required}
                      isHorizontal={!m.isVertical}
                      disabled={(m.notModifiable && getValue(m, true)) || disabled}
                      minLength={m.length}
                      maxLength={m.length}
                    />
                  )
                if (m.component === 'textarea')
                  return (
                    <TextArea
                      key={`form-model-${mi}`}
                      value={getValue(m)}
                      onChange={value => setValue(value, m)}
                      label={m.label}
                      required={m.required}
                      isHorizontal={!m.isVertical}
                      disabled={disabled}
                    />
                  )
                if (m.component === 'select')
                  return (
                    <Select
                      key={`form-model-${mi}`}
                      selectedValue={getSelectValue(m) || ''}
                      options={(m.values || []).map((v: any) => ({
                        ...v,
                        value: v.field,
                      }))}
                      label={m.label}
                      setSelectedValue={value => setSelectValue(value, m)}
                      required={m.required}
                      isHorizontal={!m.isVertical}
                      choiceNoValue
                      hideDefault
                      disabled={disabled}
                    />
                  )
                if (m.component === 'radio')
                  return (
                    <InputRadioCheckbox
                      key={`form-model-${mi}`}
                      options={(m.values || []).map((v: any) => ({
                        ...v,
                        value: v.field,
                      }))}
                      label={m.label}
                      type={m.component}
                      required={m.required}
                      selectedValues={[getSelectValue(m)]}
                      setSelectedValue={value => setSelectValue(value, m)}
                      isHorizontal={!m.isVertical}
                      disabled={disabled}
                    />
                  )
                if (m.component === 'checkbox')
                  return (
                    <InputRadioCheckbox
                      key={`form-model-${mi}`}
                      options={(m.values || []).map((v: any) => ({
                        ...v,
                        value: v.field,
                      }))}
                      label={m.label}
                      type={m.component}
                      required={m.required}
                      selectedValues={getSelectedValues(m)}
                      setSelectedValue={value => setSelectedValues(value, m)}
                      isHorizontal={!m.isVertical}
                      disabled={disabled}
                    />
                  )
                return ''
              })}
            </div>
          ))}

          {!disabled && (
            <div style={{ textAlign: 'right', color: 'red' }} className="is-size-7">
              * champs obligatoires
            </div>
          )}

          {error && <MessageError message={error} />}

          {NextButton && (
            <NextButton
              onClick={() => {
                for (let i = 0; i < form.pages.length; i++) {
                  const pageElement = document.getElementById(`page-${i}`)
                  if (!pageElement) return null

                  const inputRequired = pageElement.querySelectorAll('[required]')
                  for (let j = 0; j < inputRequired.length; j++) {
                    const input = inputRequired[j] as HTMLFormElement
                    if (!input.checkValidity()) return setActivePage(i)
                  }
                }
              }}
              loading={loading}
              htmlType="submit"
            />
          )}

          <div className="field is-grouped is-grouped-centered">
            {backLink && backLabel && (
              <p className="control">
                <Button label={backLabel} onClick={() => history.push(backLink)} type="dark" />
              </p>
            )}
            {!NextButton && !hideButton && (
              <p className="control">
                <Button
                  label="Enregistrer"
                  onClick={() => {
                    for (let i = 0; i < form.pages.length; i++) {
                      const pageElement = document.getElementById(`page-${i}`)
                      if (!pageElement) return null

                      const inputRequired = pageElement.querySelectorAll('[required]')
                      for (let j = 0; j < inputRequired.length; j++) {
                        const input = inputRequired[j] as HTMLFormElement
                        if (!input.checkValidity()) return setActivePage(i)
                      }
                    }
                  }}
                  submit
                />
              </p>
            )}
          </div>
        </div>
      </form>

      {form.pages.length > 1 && (
        <div className="tabs is-medium">
          <ul>
            {form.pages.map((p, i) => (
              <li key={nextId('tab-bottom-')} className={`${i === activePage && 'is-active'}`}>
                <a onClick={() => setActivePage(i)}>{p.label}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Form
