import React, { useCallback, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useUpdateEventDayUserMutation } from '../api/eventUser.api'
import { useGetFormQuery } from '../api/form.api'
import { useCreateUserMutation } from '../api/user.api'
import { useMyContext } from '../context/context'
import useHistoryCustom from '../hooks/useHistoryCustom'
import Form from '../shared/form/forms'

const uuid = uuidv4()

const CreateAnonymous = () => {
  const history = useHistoryCustom()
  const { settings, login, event, user: currentUser, myContext } = useMyContext()

  const [createUser] = useCreateUserMutation()
  const [updateEventDay] = useUpdateEventDayUserMutation()
  const { data: form } = useGetFormQuery({ ...myContext, formId: settings.FO_MEETING_ANONYMOUS_FORM_ID })

  const init = useCallback(async () => {
    if (settings && event) {
      if (!settings.FO_MEETING_ANONYMOUS_IS_ACTIVE) {
        history.push('')
      } else if (!currentUser) {
        const user = await createUser({
          ...myContext,
          user: { email: `${uuid}@gayakoa.com`, isExhibitor: settings?.FO_MEETING_IS_EXHIBITOR_DEFAULT },
        }).unwrap()
        localStorage.setItem('token', user.token)

        const promises = [
          ...event.days.map(ed => {
            return updateEventDay({
              ...myContext,
              userId: user.id,
              dayId: ed.id,
              day: { registered: true, registeredAt: new Date() },
            }).unwrap()
          }),
        ]
        await Promise.all(promises)

        await login(user.token)
      } else if (currentUser && !settings.FO_MEETING_ANONYMOUS_FORM_ID) {
        history.push(settings.FO_MEETING_SCAN_BADGE_REFERER || '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, event, currentUser])

  useEffect(() => {
    init()
  }, [init])

  return (
    <div>
      {currentUser && <Form afterSave2={() => history.push(settings.FO_MEETING_SCAN_BADGE_REFERER || '')} form={form} user={currentUser} />}
    </div>
  )
}

export default CreateAnonymous
