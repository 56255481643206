import { Alert, Button, Form as Form2, Input } from 'antd'
import React from 'react'
import { useCreateCompanyMutation, useUpdateCompanyMutation } from '../../api/company.api'
import { useGetFormQuery } from '../../api/form.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'
import Form from '../../shared/form/forms'
import { useSteps } from '../steps/Steps'
import { indexes } from './indexes'

const CompanyCreate = ({ company, toList }) => {
  const { myContext, userId, t } = useMyContext()
  const { participation } = useSteps()
  const { companiesFormId, companyIndexLabel, companyIndexSize } = useConf(participation)
  const [form2] = Form2.useForm()

  const [create, { error: error1 }] = useCreateCompanyMutation()
  const [update, { error }] = useUpdateCompanyMutation()
  const { data: form } = useGetFormQuery({ ...myContext, formId: companiesFormId })

  const onSubmit = async values => {
    try {
      const { siren } = await form2.validateFields()

      const body = { ...company, ...values, userId, siren }

      if (company?.id) {
        await update({ ...myContext, body, companyId: company.id }).unwrap()
        toList(company.id)
      } else {
        const result = await create({ ...myContext, body }).unwrap()
        toList(result.id)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8, paddingTop: 16 }}>
      <div>
        <Button type="primary" onClick={toList}>
          {t('BACK')}
        </Button>
      </div>
      <Form2 layout="horizontal" form={form2} initialValues={company}>
        <Form2.Item
          label={`${indexes[company.country]?.label || `${companyIndexLabel || 'SIREN de la société'}`}`}
          name="siren"
          rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
          labelCol={{ span: 10 }}
        >
          <Input disabled={!!company?.siren} style={{ width: '100%' }} />
        </Form2.Item>
      </Form2>
      <Form
        company={company}
        form={form}
        onSaveCompany={onSubmit}
        NextButton={props => (
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
            <Button {...props} type="primary">
              {t('VALIDATE')}
            </Button>
          </div>
        )}
      />
      {(error?.data?.message === 'SIREN_EXISTS' || error1?.data?.message === 'SIREN_EXISTS') && (
        <Alert showIcon message={`${companyIndexLabel} est déjà utilisé, veuillez contacter l'organisation.`} type="error" danger />
      )}
    </div>
  )
}

export default CompanyCreate
