import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams, useRouteMatch } from 'react-router'
import { useGetContextQuery } from '../api/api'
import { useGetLanguagesQuery } from '../api/v2/languages'
import { Loading } from '../appRouter'
import UserApi from '../services/api/User.api'
import UserEventApi from '../services/api/UserEvent.api'

const Context = React.createContext({
  event: null,
  eventId: null,
  domainId: null,
  settings: {},
  baseUrl: null,
  user: null,
  userId: null,
})

const navigatorLanguage = navigator.language || navigator.userLanguage
const languageCode = navigatorLanguage.substring(0, 2).toUpperCase()

export const ContextProvider = ({ children }) => {
  const location = useLocation()
  const { context } = useParams()
  const { data, isLoading } = useGetContextQuery(context)
  const { url: baseUrl } = useRouteMatch()

  const [language, setLanguage] = useState(localStorage.getItem('language') || languageCode || 'FR')
  const [user, setUser] = useState(null)
  const [errorIsExhibitor, setErrorIsExhibitor] = useState(false)
  const [isFetchingUser, setIsFetchingUser] = useState(true)

  const { data: languages2 } = useGetLanguagesQuery()

  const settings = useMemo(() => (data ? { ...data.settings, ...data.settingsAll[language] } : {}), [data, language])

  const languages = useMemo(
    () => languages2?.filter(f => settings?.FO_MEETING_LANGUAGES_SHOW?.find(ff => ff === f.key) || f.key === 'FR'),
    [languages2, settings]
  )

  const l = useMemo(() => languages?.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.translations }), {}), [languages])

  useEffect(() => {
    if (languages) {
      const pref = localStorage.getItem('language') || languageCode
      const find = languages.find(f => f.key === pref)
      const find2 = languages.find(f => f.key === settings?.FO_MEETING_LANGUAGE_DEFAULT)
      if (find) {
        setLanguage(pref)
      } else if (find2) {
        setLanguage(settings?.FO_MEETING_LANGUAGE_DEFAULT)
      } else {
        setLanguage('FR')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages])

  const t = useCallback(
    (key, values = []) => {
      if (!l?.[language]?.[key]) {
        return `${key} ${values}`
      }

      let result = l?.[language]?.[key] || l?.FR?.[key] || key

      for (let i = 0; i < values.length; i += 1) {
        const value = values[i]
        result = result.replace(`$${i + 1}`, value)
      }

      return result
    },
    [l, language]
  )

  const logout = callback => {
    localStorage.removeItem('token')
    setUser(null)
    if (callback) {
      callback()
    }
  }

  const fetchUser = useCallback(async () => {
    try {
      setIsFetchingUser(true)
      const userFetched = await new UserApi().getMe()
      const eventUser = await new UserEventApi().getByUserId(userFetched.id)
      setUser(eventUser || userFetched)
    } catch (err) {
      console.log('ERROR', err)
      if (err.message === 'Accès refusé') {
        console.log('logout')
        logout()
      }
    } finally {
      setIsFetchingUser(false)
    }
  }, [])

  const login = async newToken => {
    localStorage.setItem('token', newToken)
    await fetchUser()
  }

  useEffect(() => {
    if (data) {
      localStorage.setItem('eventId', data.id)
      localStorage.setItem('domainId', data.domainId)

      const search = new URLSearchParams(location.search)
      const newToken = search.get('token')
      if (newToken) {
        localStorage.setItem('token', newToken)
      }

      const referer = search.get('referer')
      if (referer) {
        window.location.href = referer
      } else {
        fetchUser()
      }
    }
  }, [data, fetchUser, location.search])

  useEffect(() => {
    if (user?.UserEvent?.isExhibitor) {
      setErrorIsExhibitor(true)
      // logout()
    }
  }, [user])

  if (isLoading) {
    return <Loading />
  }

  if (!data) {
    return <div className="has-text-centered">Pour accéder sur cette page, vous devez être dans le contexte d'un événement</div>
  }

  return (
    <Context.Provider
      value={{
        event: data,
        eventId: data?.id,
        domainId: data?.domainId,
        settings,
        myContext: {
          eventId: data?.id,
          domainId: data?.domainId,
          userId: user?.id,
        },
        baseUrl,
        user,
        userId: user?.id,
        logout,
        login,
        isFetchingUser,
        errorIsExhibitor,
        languages,
        language,
        setLanguage,
        t,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useMyContext = () => useContext(Context)
